import { Component, useState } from "react";
import { MODAL } from "constants/modalTypes";
import { payment_types } from "constants/paymentTypes";
import Printer from "../../../../../../config/global/printers/index";
import "./oldcheck.css";
import Services from "services/index";
import { PagePropCommonDocument } from "modules/views/pages/pageProps";
import Swal from "sweetalert2";
import { isThisTypeNode } from "typescript";
import Currencies from "constants/currencies";
import { Box, Button, Paper, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Table, TableContainer, TableHead, TextField, Typography, TableRow, TableCell, TableBody, Select, MenuItem, InputLabel, FormHelperText } from "@mui/material";
import { toast } from "react-toastify";
import DeleteIcon from '@mui/icons-material/Delete';
import { isEqual } from "lodash";
import Functions from "../../../../../../config/global/functions/index";
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
const ipcRenderer: any = window.require('electron').ipcRenderer;

type PageState = {
    check: any;
    tickCustomer: any;
    tick: any;
    updateDialog: boolean;
    discountDialog: boolean;
    payDialog: boolean;
    payments: any[];
    isPaymentProcessing: boolean;
};

type PageProps = {
    data: {
        checkId: string
        tickCustomerId: string
        startDate: Date
        endDate:Date
    };
} & PagePropCommonDocument;

const PaymentTypes = [
    "pos_bank",
    "pos_cash",
    "app_payment",
    "serve",
    "discount",
    "sodexo",
    "smart",
    "winwin",
    "multinet",
    "setcard",
    "metropol",
    "edended",
    "tips",
    "jetKurye",
    "not-payable",
    "migros-hemen",
    "getir-online",
    "trendyol",
    "yemek-sepeti"
]
class OldCreditCheck extends Component<PageProps, PageState> {
    constructor(props: any) {
        super(props);
        const getOldCheck = Services.Get.checks({
            checkId: this.props.data.checkId,
        }).data;
        const getTickCustomer = Services.Get.tick({
            tickCustomerId: this.props.data.tickCustomerId
        }).data
        this.state = {
            check: getOldCheck,
            tickCustomer: getTickCustomer,
            tick: getTickCustomer.ticks.filter((tick: any) => tick.checkId == this.props.data.checkId)[0],
            updateDialog: false,
            discountDialog: false,
            payDialog:false,
            payments:[] ,
            isPaymentProcessing: false
        };
    }

    componentDidUpdate(prevProps: Readonly<PageProps>, prevState: Readonly<PageState>, snapshot?: any): void {
        let tCustomer = this.props.getGlobalData?.Ticks?.find((t: any) => t._id == this.state.tickCustomer?._id);
        if (!isEqual(this.state.tickCustomer, tCustomer)) {
            const getOldCheck = Services.Get.checks({
                checkId: this.props.data.checkId,
            }).data;
            this.setState({
                tickCustomer: tCustomer,
                check: getOldCheck,
                tick: tCustomer?.ticks?.filter((tick: any) => tick.checkId == this.props.data?.checkId)[0]

            })
        }
    }

    DiscountDialog = () => {
        const [discount, setDiscount] = useState(0);
        const [loading, setLoading] = useState(false);
        const handleClose = () => {
            setDiscount(0);
            this.setState({
                discountDialog: false
            });
        }

        const handleDelete = async (discount: any) => {
            let checkDiscount = this.state.check?.discounts?.find((d: any) => d.note == "tick-discount" && d.amount == discount.price);
            if (!checkDiscount) {
                toast.error(this.props.router.t('error'));
                return;
            }
            setLoading(true);
            let data = {
                checkDiscount: checkDiscount._id,
                discountId: discount._id,
                customerId: this.state.tickCustomer?._id,
                tickId: this.state.tick?._id
            }
            let response = await Services.Post.removeDiscountFromTick(data);
            if (response.status) {
                toast.success(this.props.router.t('success'));
                setLoading(false);
                return;
            }
            setLoading(false);
            toast.error(this.props.router.t('error'));
        }

        const handleSubmit = async () => {
            if (discount <= 0) {
                toast.warning(this.props.router.t('invalid-discount-amount'));
                return;
            }
            if (discount > this.state.tick?.debt) {
                toast.warning(this.props.router.t('discount-amount-warning'));
                return;
            }
            setLoading(true);
            let data = {
                customerId: this.state.tickCustomer?._id,
                tickId: this.state.tick?._id,
                discount: discount
            }
            let response = await Services.Put.applyDiscountTick(data);

            if (response.status) {
                toast.success(this.props.router.t('apply-discount-success'));
                setLoading(false);
                this.setState({
                    discountDialog: false
                });
                return;
            }
            toast.error(this.props.router.t('error'));
            setLoading(false);
        }
        return (
            <Dialog open={this.state.discountDialog} onClose={handleClose} fullWidth>
                <DialogTitle>
                    <Box sx={{ background: "red", margin: "auto", width: "70%", borderRadius: "2vw" }}>
                        <Typography sx={{ padding: "1vw", color: "#fff", fontWeight: "bold", fontSize: "1.2vw" }} textAlign="center">{this.props.router.t('apply-discount')}</Typography>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <Stack direction="column" spacing={3}>
                        <Stack direction="row" alignItems="center" justifyContent="center" spacing={1} sx={{ width: "50%", margin: "auto", marginTop: 2 }}>
                            <TextField type="number" onChange={(e: any) => setDiscount(e.target.value)} onClick={() => ipcRenderer.send('showKeyboard')} label={this.props.router.t('discount')} />
                            <span>{Currencies[localStorage.getItem("defaultCurrency")!] || <>₺</>}</span>
                        </Stack>
                        {this.state.tickCustomer?.discount?.filter((discount: any) => discount.tick == this.state.tick?._id)?.length > 0 ? (
                            <TableContainer>
                                <Typography sx={{ fontWeight: "bold" }}>
                                    {this.props.router.t('applied-discounts')}
                                </Typography>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>
                                                {this.props.router.t('amount')}
                                            </TableCell>
                                            <TableCell>
                                                {this.props.router.t('creation-date')}
                                            </TableCell>
                                            <TableCell></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.state.tickCustomer?.discount?.filter((discount: any) => discount.tick == this.state.tick?._id)?.map((discount: any) => (
                                            <TableRow>
                                                <TableCell>
                                                    {discount.price}
                                                </TableCell>
                                                <TableCell>
                                                    {new Date(discount.createdAt)?.toLocaleDateString()}
                                                </TableCell>
                                                <TableCell sx={{ cursor: "pointer" }} onClick={() => handleDelete(discount)}>
                                                    <DeleteIcon />
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        ) : null}
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" disabled={loading} color="success" onClick={handleSubmit}>{this.props.router.t('save')}</Button>
                    <Button variant="outlined" disabled={loading} color="error" onClick={handleClose}>{this.props.router.t('cancel')}</Button>
                </DialogActions>
            </Dialog>
        )
    }

    onPayment = () => {
        if (this.state.isPaymentProcessing) return;
    
        Swal.fire({
            title: this.props.router.t("please-specify-details"),
            icon: "question",
            html: `
                ${this.props.router.t('quantity')}
                <div style="margin:auto"> 
                    <input type="number" id="amount" style="width:50%;margin:auto" class="swal2-input"/> 
                    <span style="display:inline">
                        ${Currencies[localStorage.getItem("defaultCurrency")!] || "₺"}
                    </span>
                </div>`,
            focusConfirm: false,
            preConfirm: () => {
                const amount = (Swal.getPopup()?.querySelector('#amount') as HTMLInputElement)?.value;
                if (!amount) {
                    Swal.showValidationMessage(this.props.router.t('please-enter-amount-type'));
                }
                const remainingAmount = this.state.tick.debt - this.state.tick.on_payment;
                if (Number(amount) > remainingAmount) {
                    Swal.showValidationMessage(`${this.props.router.t('cannot-exceed-amount')} <br> 
                        ${this.props.router.t('current-remaining-amount')}: ${remainingAmount}`);
                }
                return { amount: amount }
            },
            showCancelButton: true,
        }).then(result => {
            if (result.isConfirmed) {
                Swal.fire({
                    icon: "question",
                    title: this.props.router.t('tick-pay-ques'),
                    showCancelButton: true,
                    confirmButtonText: this.props.router.t('yes'),
                    cancelButtonText: this.props.router.t('no')
                }).then(async result2 => {
                    if (result2.isConfirmed) {
                        Swal.fire({
                            title: this.props.router.t("pleaseWait").toCapitalizeCase(),
                            html: this.props.router.t("uploading") + "...",
                            showConfirmButton: false,
                            allowOutsideClick: false,
                            willOpen: () => Swal.showLoading()
                        });
    
                        this.setState({ isPaymentProcessing: true });
    
                        let params = {
                            branchTickId: this.state.tickCustomer._id,
                            tickId: this.state.tick._id,
                            payment: Number(result?.value?.amount),
                        };
    
                        try {
                            let response = await Services.Put.payTick(params);
                            if (response.status) {
                                let updatedTick = { ...this.state.tick, on_payment: this.state.tick.on_payment + params.payment };
    
                                if (updatedTick.on_payment >= updatedTick.debt) {
                                    updatedTick.isActive = false;
                                }
    
                                this.setState({ tick: updatedTick });
    
                                let allTicks = this.props.getGlobalData.Ticks?.map(tick => 
                                    tick._id === this.props.data.tickCustomerId
                                        ? { 
                                            ...tick, 
                                            ticks: tick.ticks.map((t: any) => 
                                                t._id === this.state.tick._id
                                                    ? { ...t, on_payment: t.on_payment + params.payment, isActive: updatedTick.isActive }
                                                    : t
                                            )
                                          }
                                        : tick
                                );
    
                                this.props.setGlobalData({ Ticks: allTicks });
    
                                Swal.fire({
                                    icon: "success",
                                    title: updatedTick.isActive 
                                        ? this.props.router.t("payment-success")
                                        : this.props.router.t("payment-success-tick-all")
                                });
                            } else {
                                Swal.fire({
                                    icon: "error",
                                    title: this.props.router.t("ERROR")
                                });
                            }
                        } catch (error) {
                            console.error("Payment error:", error);
                            Swal.fire({
                                icon: "error",
                                title: this.props.router.t("ERROR")
                            });
                        } finally {
                            this.setState({ isPaymentProcessing: false });
                        }
                    }
                });
            }
        });
    };
    deleteRow(uid: any) {
        this.setState({
            payments: this.state.payments.filter((row: any) => row.uid != uid),
        });
    }

    addRow() {
        this.setState({
            payments: [
                ...this.state.payments,
                { 
                    payment: {
                        type: 1,
                        amount: 0,
                        currency: this.props.getGlobalData?.CurrencySettings?.defaultCurrency || "TL",
                    },              
                    uid: Functions.createUUID(),
                },
            ],
        });
    }

    handlePriceChange(value: any, uid: any) {
        let payment = this.state.payments.findSingle("uid", uid);
        payment.payment.amount = Number(value);
        this.setState({
            payments: this.state.payments,
        });
    };

    handleTypeChange(value: any, uid: any) {
        let payment = this.state.payments.findSingle("uid", uid);
        payment.payment.type = Number(value);
        this.setState({
            payments: this.state.payments,
        });
    };
    
    Payment = (props: any) => {
        return (
            <tr style={{width: "100%"}}>
                <td style={{width: "190px", textAlign: "center"}}>
                    <select
                        onChange={(event) => this.handleTypeChange(event.target.value, props.uid)}
                        defaultValue={props.payment.type}
                    >
                        {
                            PaymentTypes.map((payment_type: any, i: number) => (
                                <option value={i + 1}> {this.props.router.t(payment_type)} </option>
                            ))
                        }
                    </select>
                </td>
                <td style={{width: "140px", textAlign: "center"}}>
                    <input
                        min={0}
                        type={"number"}
                        defaultValue={props.payment.amount}
                        onChange={(event) => this.handlePriceChange(event.target.value, props.uid)}
                        onClick={() => {
                            ipcRenderer.send('showKeyboard')
                        }}
                    />
                </td>
                <td style={{width: "110px", textAlign: "center"}}>
                    {props.payment.currency}
                </td>
                <td style={{width: "110px", textAlign: "center"}}>
                    { this.state.payments.length > 1 ?
                        <button
                        onClick={() => this.deleteRow(props.uid)}
                        className="btn btn-danger">{this.props.router.t("delete")}</button> : null } 
                </td>
            </tr>
        );
    }
    payTickEE = () => {
        const [pay, setPay] = useState(0);
        const handleClose = () => {
            setPay(0);
            this.setState({
                payDialog: false
            });
        }
     return   <Dialog open={this.state.payDialog} onClose={handleClose} fullWidth>
               <div className="oldCheckContainer no-overflow">
                    <table cellSpacing="0" cellPadding="0">
                        <thead>
                        <tr>
                            <td>
                                <table
                                    style={{width: "550px"}}
                                    cellSpacing="0"
                                    cellPadding="1"
                                >
                                    <tr style={{color: "white", background: "grey"}}>
                                        <th style={{width: "190px", textAlign: "center"}}>
                                            {this.props.router.t("type")}
                                        </th>
                                        <th style={{width: "140px", textAlign: "center"}}>
                                            {this.props.router.t("amount")}
                                        </th>
                                        <th style={{width: "110px", textAlign: "center"}}>
                                            {this.props.router.t("genus")}
                                        </th>
                                        <th style={{width: "110px", textAlign: "center"}}>
                                            {this.props.router.t("action")}
                                        </th>
                                    </tr>
                                </table>
                            </td>
                        </tr>
                        </thead>
                        <tbody>
                        <div style={{height: "32vh", overflow: "auto"}}>
                            <table
                                style={{width: "550px"}}
                                cellSpacing="0"
                                cellPadding="1"
                            >
                                {this.state.payments.length>0?
                                    this.state.payments.map((payment: any) => (
                                        <this.Payment {...payment}/>
                                    )):this.addRow()
                                }
                                <tr>
                                    <th style={{width: "20vw", textAlign: "center"}}></th>
                                    <th style={{width: "20vw", textAlign: "center"}}>
                                        <button
                                            className="btn btn-primary"
                                            onClick={() => this.addRow()}
                                        >
                                            {this.props.router.t("add")}
                                        </button>
                                    </th>
                                    <th style={{width: "10vw", textAlign: "center"}}></th>
                                    <th style={{width: "9vw", textAlign: "center"}}></th>
                                </tr>
                            </table>
                        </div>
                        </tbody>
                    </table>
                </div>
              
            </Dialog>
            
      /*  Swal.fire({
            icon: "question",
            title: this.props.router.t('tick-pay-ques'),
            showCancelButton: true,
            showConfirmButton: true,
            cancelButtonText: this.props.router.t('no'),
            confirmButtonText: this.props.router.t('yes')
        }).then(async result => {
            if (result.isConfirmed) {
                let params = {
                    branchTickId: this.state.tickCustomer._id,
                    tickId: this.state.tick._id,
                    payment: this.state.tick.debt,
                }
                let result = await Services.Put.payTick(params)
                if (result.status) {
                    Swal.fire({
                        icon: "success",
                        title: this.props.router.t("payment-success")
                    })
                } else {
                    Swal.fire({
                        icon: "error",
                        title: this.props.router.t("ERROR")
                    })
                }
            }
        })*/
    }
    getTotalPayment() {
        let sum = 0;
        this.state.check?.payments?.forEach((payment: any) =>
            payment.type == 14 ? undefined : (sum += payment.amount)
        );
        return sum;
    }

    getTotalOrders() {
        let sum = 0;
        this.state.check?.orders?.forEach((o: any) => {
            o.products.forEach((p: any) => sum += (p.isServe || p.status === 0 ? 0 : p.price));
        });
        return sum;
    }

    getProductCount() {
        let sum = 0;
        this.state.check?.orders?.forEach((o: any) => {
            o.products?.forEach((p: any) => sum += p.quantity);
        });
        return sum;
    }

   
    UpdateTickDialog = () => {
        const [tickAmount, setTickAmount] = useState(this.state?.tick?.debt);
        const [loading, setLoading] = useState(false);
        const [paymentType, setPaymentType] = useState(1);

        const handleSubmit = async () => {
            if (tickAmount - this.state.tick.debt == 0) {
                toast.warning(this.props.router.t('tick-update-amount-error'));
                return;
            }
            if (tickAmount < this.state.tick.on_payment) {
                toast.warning(this.props.router.t('tick-update-less-error'));
                return;
            }
            setLoading(true);
            let response = await Services.Put.updateTick({
                checkId: this.state.check._id,
                tickId: this.state.tickCustomer._id,
                amount: Number(tickAmount - this.state.tick.debt),
                paymentType: paymentType
            });
            if (response.customData?.status == 400) {
                setLoading(false);
                toast.error(this.props.router.t('tick-update-payment-error'));
                return;
            }
            if (response.status) {
                let allTicks = this.props.getGlobalData.Ticks?.map((tick: any) => {
                    if (tick._id == response.data?.customer?._id?.toString()) {
                        tick = response.data.customer;
                    }
                    return tick;
                });
                this.props.setGlobalData({
                    Ticks: allTicks
                });
                this.setState({
                    updateDialog: false,
                    tickCustomer: response.data.customer,
                    tick: response.data.tick,
                    check: response.data.check
                });
                setLoading(false);
                toast.success(this.props.router.t('tick-update-success'));
                return;
            }
            toast.error(this.props.router.t('server-error'));
            setLoading(false);

        }

        const handleChange = (value: number) => {
            setTickAmount(Number(value));
            let cAmount = Number(value) - this.state.tick.debt;

        }

        const handleClose = () => {
            this.setState({
                updateDialog: false
            });
            setTickAmount(this.state?.tick?.debt);
        }

        return (
            <Dialog open={this.state.updateDialog} onClose={handleClose} fullWidth>
                <DialogTitle>{this.props.router.t('update-tick')}</DialogTitle>
                <DialogContent>
                    <Stack direction="row" spacing={2} alignItems="center">
                        <TextField sx={{
                            margin: "auto",
                            width: "50%",
                            marginTop: "1.5vw"
                        }} type="number" onClick={() => ipcRenderer.send("showKeyboard")} label={this.props.router.t('tick-amount')} value={tickAmount} onChange={(e: any) => handleChange(e.target.value)} />
                        {this.state?.tick?.debt - tickAmount > 0 ? (
                            <Box>
                                <InputLabel id="pTypeLabel">{this.props.router.t('payment-type')}</InputLabel>
                                <Select labelId="pTypeLabel" value={paymentType} onChange={(e: any) => setPaymentType(e.target.value)}>
                                    {PaymentTypes.map((type: any, i: number) => {
                                        if (type == "tick") return;
                                        return (
                                            <MenuItem value={i + 1}>{this.props.router.t(type)}</MenuItem>
                                        )
                                    }
                                    )}
                                </Select>
                                <FormHelperText>
                                    {this.props.router.t('update-tick-type-info')}
                                </FormHelperText>
                            </Box>
                        ) : null}
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" color="success" onClick={handleSubmit} disabled={loading}>
                        {this.props.router.t('save')}
                    </Button>
                    <Button variant="outlined" color="error" onClick={handleClose}>
                        {this.props.router.t('cancel')}
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
    print = () => {
        let total = 0;
        
        this.state.check.payments.map((p: any) => total += p.amount);
        const foundTable = this.props.getGlobalData.AllTables.findSingle("_id", this.state.check.table);
        const clonedTable = { ...foundTable };
        clonedTable.checkNo = this.state.check.checkNo ?? "1";
        clonedTable.serviceFee = this.state.check.serviceFee ?? [];
        let printData: any = {
            orders: this.state.check.orders,
            tableInfo:clonedTable,
            payments: this.state.check.payments.map((p: any) => ({
                type: this.props.router.t(p.type),
                amount: p.amount
            })),
            discount: this.state.check.discounts,
            cover: this.state.check.covers,
            service: this.state.check.serviceFee,
            total: {
                amount: total,
                currency: this.props.getGlobalData?.CurrencySettings?.defaultCurrency || "TL"
            }
        };

        let table = this.props.getGlobalData.AllTables.findSingle("_id", this.state.check.table);
        if (table) {
            let section = this.props.getGlobalData.Sections.findSingle("_id", table?.section)?.title;
            if (section) {
                printData = {
                    ...printData,
                    table: `${section} - ${table.title}`
                }
            }
        }
        Printer.ProductsInOrder.printProductsInOrder(printData,this.props.getGlobalData?.Products,this.props.router.t);
    }
    render() {
        return (
            <>
                <div className="oldCheckContainer">
                    <this.UpdateTickDialog />
                    <this.DiscountDialog />
                    <div
                        style={{
                            width: "100%",
                            padding:"3px",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-evenly",
                        }}
                    >
                    <Button variant="contained" startIcon={<KeyboardReturnIcon />} color="error" sx={{ marginRight: 1 }} onClick={() => {
                        this.props.closeModal();
                        this.props.openModal(MODAL.GET_CREDIT_LIST,{
                            checkId: this.props.data.checkId,
                            tickCustomerId: this.props.data.tickCustomerId,
                            startDate: this.props.data.startDate,
                            endDate: this.props.data.endDate
                        });
                    }}>{this.props.router.t('back-to-list')}</Button>
                    <table style={{ width: "90%", border: "1px solid" }}>
                        <tr style={{ width: "50%", background: "grey", color: "white" }}>
                            <th>{this.props.router.t("payment-status")}</th>
                            <th>{this.props.router.t("date")}</th>
                        </tr>
                        <tr style={{ width: "50%" }}>
                            <th>
                                <span style={{ color: this.state.tick.isActive ? "red" : "green" }}>{!this.state.tick.isActive
                                    ? this.props.router.t("paid")
                                    : this.props.router.t("not-paid")}</span>
                            </th>
                            <th>
                                {new Date(this.state.check.createdAt).toLocaleTimeString()}
                            </th>
                        </tr>
                    </table>
                    </div>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-evenly",
                        }}
                    >
                        <div>
                            <div style={{ textAlign: "center" }}>
                                {" "}
                                <strong>{this.props.router.t("orders")}</strong>{" "}
                            </div>
                            <table
                                style={{ width: "33vw", height: "15vh" }}
                                cellSpacing="0"
                                cellPadding="0"
                            >
                                <tr>
                                    <td>
                                        <table
                                            style={{ width: "100%" }}
                                            cellSpacing="0"
                                            cellPadding="1"
                                        >
                                            <tr
                                                style={{
                                                    color: "white",
                                                    background: "chocolate",
                                                    height: "52px",
                                                }}
                                            >
                                                <th style={{ width: "5vw" }}>
                                                    {this.props.router.t("number")}
                                                </th>
                                                <th style={{ width: "17vw" }}>
                                                    {this.props.router.t("product-name")}
                                                </th>
                                                <th style={{ width: "11vw" }}>
                                                    {this.props.router.t("price")}
                                                </th>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div style={{ height: "22vh", overflow: "auto" }}>
                                            <table cellSpacing="0" cellPadding="1">
                                                {this.state.check?.orders?.map((order: any) => {
                                                    return (
                                                        <>
                                                            {
                                                                order.products?.map((product: any) => (

                                                                    <tr>
                                                                        <th style={{ width: "5vw" }}>{product.quantity}</th>
                                                                        <th style={{ width: "17vw" }}>
                                                                            {product.productName}
                                                                        </th>
                                                                        <th style={{ width: "11vw" }}> { product.status === 0 ? this.props.router.t("cancel") : product.isServe ? this.props.router.t("catering") : product.price.toFixed(2) } {product.isServe || product.status === 0 ? "":Currencies[localStorage.getItem("defaultCurrency")!] || <>₺</>}</th>
                                                                    </tr>

                                                                ))
                                                            }
                                                        </>
                                                    )
                                                })}

                                            </table>
                                        </div>
                                    </td>
                                </tr>
                            </table>
                            <div>
                                <tr>
                                    <th style={{ width: "8vw", border: "1px solid" }}>
                                        {this.props.router.t("total")}
                                    </th>
                                    <th style={{ width: "15vw", border: "1px solid" }}>
                                        {this.getProductCount()}{" "}
                                        {this.props.router.t("number-product")}
                                    </th>
                                    <th style={{ width: "10vw", border: "1px solid" }}>
                                        {this.getTotalOrders()} {Currencies[localStorage.getItem("defaultCurrency")!] || <>₺</>}
                                    </th>
                                </tr>
                            </div>
                        </div>

                        <div style={{ marginLeft: "2px" }}>
                            <div style={{ textAlign: "center" }}>
                                {" "}
                                <strong>{this.props.router.t("payments")}</strong>{" "}
                            </div>
                            <table style={{ width: "33vw" }} cellSpacing="0" cellPadding="0">
                                <tr>
                                    <td>
                                        <table
                                            style={{ width: "100%" }}
                                            cellSpacing="0"
                                            cellPadding="1"
                                        >
                                            <tr style={{ color: "white", background: "green", height:"52px" }}>
                                                <th style={{ width: "12vw" }}>
                                                    {this.props.router.t("type")}
                                                </th>
                                                <th style={{ width: "11vw" }}>
                                                    {this.props.router.t("quantity")}
                                                </th>
                                                <th style={{ width: "10vw" }}>
                                                    {this.props.router.t("creation-date")}
                                                </th>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div style={{ height: "22vh", overflow: "auto" }}>
                                            <table cellSpacing="0" cellPadding="1">
                                                {this.state.check?.payments?.map((payment: any) => (
                                                    <tr>
                                                        <th style={{ width: "12vw" }}>
                                                            {this.props.router.t(payment_types[Number(payment.type) - 1])}
                                                        </th>
                                                        <th style={{ width: "11vw" }}>
                                                            {payment.amount.toFixed(2)}
                                                            {payment.currency}
                                                        </th>
                                                        <th style={{ width: "10vw" }}>
                                                            {new Date(payment.createdAt).toLocaleTimeString()}
                                                        </th>
                                                    </tr>
                                                ))}
                                                <tr>
                                                    <th style={{ width: "12vw" }}>{this.props.router.t("paid-amount")}</th>
                                                    <th style={{ width: "11vw" }}>{this.state.tick.on_payment.toFixed(2)} {Currencies[localStorage.getItem("defaultCurrency")!] || <>₺</>}</th>
                                                    <th style={{ width: "10vw" }}></th>
                                                </tr>
                                                <tr>
                                                    <th style={{ width: "12vw" }}>{this.props.router.t("remaining-amount")}</th>
                                                    <th style={{ width: "11vw" }}>{(this.state.tick.debt - this.state.tick.on_payment).toFixed(2)} {Currencies[localStorage.getItem("defaultCurrency")!] || <>₺</>}</th>
                                                    <th style={{ width: "10vw" }}></th>
                                                </tr>
                                            </table>
                                        </div>
                                    </td>
                                </tr>
                            </table>
                            <div>
                                <tr>
                                    <th style={{ width: "8vw", border: "1px solid" }}>
                                        {this.props.router.t("total")}
                                    </th>
                                    <th style={{ width: "15vw", border: "1px solid" }}>
                                        {this.state.check?.payments?.length}{" "}
                                        {this.props.router.t("unit-payment")}
                                    </th>
                                    <th style={{ width: "10vw", border: "1px solid" }}>
                                        {this.getTotalPayment().toFixed(2)} {Currencies[localStorage.getItem("defaultCurrency")!] || <>₺</>}
                                    </th>
                                </tr>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="changePriceButtons">
                    <>
                        <button className="oldTickButton"
                            style={{ background: "blue" }}
                            onClick={() => this.print()}
                        >
                            {" "}
                            {this.props.router.t("print")}{" "}
                        </button>
                        <button className="oldTickButton" style={{ marginLeft: 2, marginRight: 2, backgroundColor: "orange" }} onClick={() => this.setState({ updateDialog: true })}>{this.props.router.t('update-tickets')}</button>
                    </>

                    {this.state.tick.isActive ? (
                        <>
                            <button className="oldTickButton" style={{ background: "green" }} onClick={() => this.setState({ discountDialog: true })}>{this.props.router.t('discounts')}</button>
                            <button className="oldTickButton" onClick={() => this.onPayment()}>{this.props.router.t('pay')}</button>
                        </>
                    ) : null

                    }
                </div>
            </>
        );
    }
}

export default OldCreditCheck;
